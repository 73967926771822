import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import { breakPoints } from "../../services/constants";
import EventCard from "./EventCard";

EventGridContainer.propTypes = {
  events: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      slug: PropTypes.string,
      start_date: PropTypes.string,
      end_date: PropTypes.string,
      thumb: PropTypes.string,
      is_online: PropTypes.bool,
      event_location: PropTypes.object,
    })
  ),
  promoterCode: PropTypes.string
};

export default function EventGridContainer(props) {
  const { events, promoterCode } = props;

  return (
    <GridContainer>
      {(events || []).map((event, index) => (
        <EventCard event={event} key={index} promoterCode={promoterCode} />
      ))}
    </GridContainer>
  );
}
const GridContainer = styled.div`
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto;
  justify-content: center;
  gap: 15px;
  width: 100%;
  @media ${breakPoints.mobileL} {
    grid-template-columns: repeat(auto-fill, 240px);
  }
  @media ${breakPoints.tablet} {
    gap: 16px;
    row-gap: 20px;
    grid-template-columns: repeat(auto-fill, 258px);
  }
`;
