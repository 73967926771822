import dayjs from "dayjs";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import {
  Accordion,
  Button,
  Card,
  InputGroup,
  OverlayTrigger,
  Spinner,
  Tooltip,
} from "react-bootstrap";
import { DebounceInput } from "react-debounce-input";
import { useTranslation } from "react-i18next";
import { removeCheckoutData } from "../../../Helpers/checkout";
import DateHelper from "../../../Helpers/date";
import sendToLogger from "../../../Helpers/errorLogger";
import EventHelper from "../../../Helpers/event";
import {
  pushDataLayerAndEvent,
  pushGAEvent,
} from "../../../Helpers/tagManager";
import piscadela from "../../../assets/img/piscadela.png";
import sadSmille from "../../../assets/img/sad-smille.png";
import { ReactComponent as Download } from "../../../assets/svg/Download.svg";
import { ReactComponent as Ticket } from "../../../assets/svg/ticket-exhibit.svg";
import { ReactComponent as PaperPlane } from "../../../assets/svg/paper-plane-black.svg";
import DownloadApp from "../../../components/DownloadApp";
import Loader from "../../../components/Loader";
import NewButton from "../../../components/NewButton";
import { useAuth } from "../../../hooks/useAuth";
import {
  orderStatus,
  paymentTypeDictionary,
} from "../../../services/constants";
import MoengageService from "../../../services/moengage";
import NewApi from "../../../services/new-api";
import "./index.css";
import { useZendesk } from "../../../hooks/useZendesk";
import DigitalCardExample from "../../Compras/ResultPage/components/DigitalCardExample";
import RechargeDigitalCardModal from "../../Compras/ResultPage/components/RechargeDigitalCardModal";
import useWindowSize from "../../../hooks/useWindowSize";

const RECHARGE_LINK_URL =
  "https://rc.zig.fun/cashless-digital/?utm_campaign=fluxo360&utm_source=marketplace-zig&utm_medium=checkout-btn";
const api = new NewApi();
const isWhitelabel = !!Number(process.env.REACT_APP_IS_WHITELABEL);

export default function Index() {
  const [arraySearch, setArraySearch] = useState([]);
  const [search, setSearch] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadingButton, setLoadingButton] = useState(false);
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [isRechargeModalOpen, setRechargeModalOpen] = useState(false);
  const { user } = useAuth();
  const { t } = useTranslation();
  const { hide } = useZendesk();
  const isMobile = useWindowSize();

  const carregaDados = useCallback(
    (page = currentPage) => {
      const currentPosition = window.scrollY;
      if (page > 1) {
        setLoadingButton(true);
      } else {
        setLoading(true);
      }
      api
        .get(`tickets?page=${page}&per_page=20`)
        .then(({ data }) => {
          if (data.data.length > 0) {
            setData((prevData) => [...prevData, ...data.data]);
            setArraySearch((prevData) => [...prevData, ...data.data]);
            setCurrentPage((prevPage) => prevPage + 1);
          }
          setHasMore(data.meta.last_page !== data.meta.current_page);
        })
        .catch((err) => {
          sendToLogger(err);
        })
        .finally(() => {
          setLoading(false);
          setLoadingButton(false);
          setTimeout(() => {
            window.scrollTo({
              left: 0,
              top: currentPosition,
              behavior: "auto",
            });
          }, 100);
        });
    },
    [currentPage]
  );

  useEffect(() => {
    hide();
    carregaDados();
    removeCheckoutData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function findTicketsByEvent(searchTicket) {
    if (data) {
      if (searchTicket !== "") {
        const search = arraySearch.filter((ticket) =>
          ticket.name
            .toString()
            .toLowerCase()
            .includes(searchTicket.toString().toLowerCase())
        );
        if (search.length > 0) {
          setData(search);
          setSearch(false);
        } else {
          setData([]);
          setSearch(true);
        }
      } else {
        setData(arraySearch);
        setSearch(false);
      }
    }
  }

  function changeArrow(idx) {
    let arrowUp = document.getElementById(idx + "up");
    let arrowDow = document.getElementById(idx + "dw");
    let header = document.getElementById(idx + "hd");
    let card = document.getElementById(idx + "cd");
    var hidden = arrowUp.hasAttribute("hidden");
    if (hidden) {
      arrowUp.removeAttribute("hidden");
      arrowDow.setAttribute("hidden", true);
      header.classList.add("header-collapsed");
      card.classList.add("card-collapser");
      card.classList.remove("card-acordion");
    } else {
      arrowUp.setAttribute("hidden", true);
      arrowDow.removeAttribute("hidden");
      header.classList.remove("header-collapsed");
      card.classList.remove("card-collapser");
      card.classList.add("card-acordion");
    }
  }

  async function sendTicketEvent(allOrder, order, ticket) {
    await MoengageService.createEvent({
      type: "event",
      customer_id: user.id,
      actions: [
        {
          action: "Save Ticket",
          attributes: {
            orderTokenTicket: allOrder.token_ticket,
            orderPaymentType: allOrder.payment_type,
            ...ticket,
            event: {
              id: order.id,
              name: order.name,
              startDate: order.start_date,
              endDate: order.end_date,
              ageRange: order.age_range,
            },
          },
          platform: "web",
        },
      ],
    });
  }
  async function sendTransferEventAction(order) {
    try {
      pushDataLayerAndEvent({
        tkt_event_name: order.name,
        event_local: order.address_name,
        event_date: dayjs(order.start_date).format("DD/MM/YYYY"),
        user_id: user.id,
        is_buyer: user.is_buyer,
        active_buyer: user.active_buyer,
        last_order_ago: user.last_order_ago,
      });
    } catch {}

    pushGAEvent("clk_ticket_transfer");
  }

  const handleOpenModal = () => {
    setIsOpen(!isOpen);
  };

  const sendGa4Event = (order) => {
    pushGAEvent("clk_view_ticket", {
      event_name: order.name,
      event_producedby: order.producedby,
      event_local: order.address_name,
      event_city: order.city,
      event_date: order.start_date,
      user_id: user.id,
    });
  };

  const handleSeeTicket = (allOrder, order, ticket) => {
    sendTicketEvent(allOrder, order, ticket);
    sendGa4Event(order);

    downloadPdfTicket(order, ticket);
  };
  const downloadPdfTicket = (order, ticket) => {
    window.open(
      `${process.env.REACT_APP_TICKET_URL}/${Buffer.from(
        `${ticket.code}$${order.id}`
      ).toString("base64")}`,
      "_target"
    );
  };
  return (
    <>
      {loading ? (
        <>
          <div className="loading-container"></div>
          <Loader></Loader>
        </>
      ) : (
        <div id="page-mytickets" className="container">
          <div className="row page-header">
            <div className="col-12">
              <h2>{t("Header.myTickets")}</h2>
              <div className="event-search">
                <InputGroup className="mb-3">
                  <InputGroup.Prepend>
                    <InputGroup.Text id="basic-addon1">
                      <i className="fas fa-search"></i>
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <DebounceInput
                    placeholder={t("MyTickets.searchByEventName")}
                    aria-label="ingresso"
                    aria-describedby="basic-addon1"
                    debounceTimeout={500}
                    minLength={1}
                    className="form-control"
                    onChange={(e) => findTicketsByEvent(e.target.value)}
                  />
                </InputGroup>
              </div>
            </div>
          </div>
          {!isWhitelabel && (
            <div className=" d-none d-md-flex mt-4 mb-4">
              <DownloadApp
                isOpen={isOpen}
                handleOpenModal={handleOpenModal}
                seeTicket={false}
              />
            </div>
          )}
          <br />
          {data?.length <= 0 ? (
            <>
              {!search ? (
                <div className="empty-tickets text-center">
                  <h3 className="">{t("MyTickets.noTicketFound")}</h3>
                  <img src={sadSmille} alt="" />
                </div>
              ) : (
                <div className="empty-tickets text-center">
                  <h3 className="">{t("MyTickets.noTicketFoundAgain")}</h3>
                  <img src={sadSmille} alt="" />
                </div>
              )}
            </>
          ) : (
            data?.map((order, idx) => (
              <div className="row ticket-card" key={idx + 1}>
                {/* Header - Event Infos */}
                <div className="col-12 tickets-event-header">
                  <div className="event-date">
                    <span className="day">{EventHelper.startDay(order)}</span>
                    <span className="month">
                      {EventHelper.startMonth(order)}
                    </span>
                  </div>
                  <div className="event-name">
                    <h2 className="event-title-tickets">{order.name}</h2>
                  </div>
                </div>

                <div className="col-12 ticket-location" key={idx}>
                  <p>
                    <i className="far fa-compass"></i>{" "}
                    {order.address || "Não informado"}
                    <br />
                    <i className="far fa-clock"></i>{" "}
                    {EventHelper.formattedDay(order)}
                  </p>
                </div>
                <div className="col-12 col">
                  {order.orders.map((allOrder, idx) => (
                    <Fragment key={idx}>
                      <Accordion>
                        <Card className="card-acordion" id={allOrder.id + "cd"}>
                          <Card.Header
                            className="card-header-config"
                            id={allOrder.id + "hd"}
                          >
                            <span
                              className={`badge badge-pill ${
                                allOrder.status === "finalizado"
                                  ? "badge-success"
                                  : "badge-secondary"
                              }`}
                            >
                              {orderStatus(allOrder.status, t)}
                            </span>
                            <span className="order-token-ticket">
                              {t("MyTickets.order")} {allOrder.token_ticket}
                            </span>
                            <span>
                              {t("MyTickets.paymentMethod")}{" "}
                              {t(
                                `MyTickets.${
                                  paymentTypeDictionary[allOrder.payment_type]
                                }`
                              )}
                            </span>
                            <span>
                              {t("MyTickets.placedAt")}{" "}
                              {DateHelper.format(
                                allOrder.created_at,
                                "DD/MM/YYYY HH:mm"
                              )}
                            </span>

                            <Accordion.Toggle
                              as={Button}
                              id="toggle-tickets"
                              variant="link"
                              eventKey="0"
                              className="show-tickets-dropdown"
                              onClick={(e) => changeArrow(allOrder.id)}
                            >
                              {t("MyTickets.showTickets")}
                              <i
                                className="fa fa-angle-up fa-lg arrows"
                                id={allOrder.id + "up"}
                                hidden
                              ></i>
                              <i
                                className="fa fa-angle-down fa-lg arrows"
                                id={allOrder.id + "dw"}
                              ></i>
                            </Accordion.Toggle>
                          </Card.Header>
                          <Accordion.Collapse eventKey="0">
                            <Card.Body>
                              {allOrder.tickets.length <= 0 ? (
                                <>
                                  <div className="empty-tickets text-center p-4">
                                    <h3 className="">
                                      {t("MyTickets.ticketGenerationInfo")}
                                    </h3>
                                    <img src={piscadela} alt="" />
                                  </div>
                                </>
                              ) : (
                                <div>
                                  {order.event_status === "ativo" &&
                                    !isWhitelabel &&
                                    !!order.enable_digital_card && (
                                      <div className="d-flex flex-column flex-md-row align-items-center justify-content-center gap-3 pb-3 py-md-3 digital-card-ticket">
                                        <div className="col-12 col-md-2 d-flex justify-content-center digital-card-example-container m-0 p-0">
                                          <DigitalCardExample
                                            width="110px"
                                            height="120px"
                                          />
                                        </div>

                                        <div className="col-12 col-md-7 d-flex flex-column gap-1 digital-card-recharge-ticket">
                                          <h4 className="text-center m-0 text-md-left">
                                            {t(
                                              "DigitalCardRecharge.rechargeNow"
                                            )}
                                          </h4>
                                          <p className="text-center text-md-left">
                                            {t(
                                              "CheckoutStatus.concluded.subtitleDigitalCard"
                                            )}
                                          </p>
                                        </div>

                                        <div className="d-flex justify-content-center align-items-center col-12 col-md-3 p-0">
                                          <NewButton
                                            className="primary-contained"
                                            content={t(
                                              "CheckoutStatus.buttons.recharge"
                                            )}
                                            onClick={() => {
                                              pushGAEvent(
                                                "clk_recharge_online_btn"
                                              );
                                              if (!isMobile) {
                                                setRechargeModalOpen(true);
                                              } else {
                                                window.open(RECHARGE_LINK_URL);
                                              }
                                            }}
                                          />
                                        </div>
                                      </div>
                                    )}
                                  {allOrder.tickets.map((ticket, idx) => {
                                    const blockedTransfers =
                                      ticket.transfers.filter(
                                        ({ transfer_status }) =>
                                          transfer_status === "completed" ||
                                          transfer_status === "waiting"
                                      ) || [];

                                    const activeTransfer =
                                      ticket.transfers.find(
                                        ({ active }) => active === 1
                                      ) || blockedTransfers[0];

                                    const canceledTransfer =
                                      ticket.transfers.find(
                                        ({ transfer_status }) =>
                                          transfer_status === "canceled"
                                      );

                                    const lastReceived =
                                      ticket.transfers?.findLast(
                                        ({ destination_user_id, ticket_id }) =>
                                          destination_user_id === user.id &&
                                          ticket_id === ticket.id
                                      );

                                    const isCancelled =
                                      canceledTransfer?.active === 1 ||
                                      (canceledTransfer &&
                                        ticket.transfers.findIndex(
                                          ({ id }) => canceledTransfer.id === id
                                        ) === 0);

                                    return (
                                      <div
                                        className="ticket-orders justify-content-md-between flex-column flex-md-row"
                                        style={{ gap: "1rem" }}
                                        key={idx}
                                      >
                                        <div
                                          className="col-md-7 col-lg-7 mt-2 d-flex flex-column p-0"
                                          style={{ gap: "1rem" }}
                                        >
                                          <p className="m-0">
                                            <strong style={{ color: "black" }}>
                                              {ticket.ticket_name || "-"}
                                            </strong>
                                            <br />
                                            <strong>
                                              {t("MyTickets.participant")}
                                            </strong>

                                            {activeTransfer ? (
                                              <>
                                                {
                                                  activeTransfer.destination_email
                                                }
                                                <br />
                                                <strong>
                                                  {t("MyTickets.transferDate")}
                                                </strong>
                                                {DateHelper.format(
                                                  activeTransfer.date_transfer,
                                                  "DD/MM/YYYY [às] HH:mm"
                                                )}
                                              </>
                                            ) : (
                                              <>
                                                {ticket.attendee_name}
                                                <br />
                                                {ticket.email}
                                              </>
                                            )}
                                          </p>

                                          {allOrder.status === "finalizado" &&
                                            ticket.status === 1 &&
                                            lastReceived && (
                                              <div className="d-none d-md-flex p-0">
                                                <div
                                                  className="transferred text-center px-2 py-1"
                                                  id="received-from"
                                                >
                                                  {`${t(
                                                    "NominalTransfer.receivedFrom"
                                                  )}${lastReceived?.user_name}`}
                                                </div>
                                              </div>
                                            )}
                                        </div>

                                        {isCancelled && (
                                          <div
                                            className="d-flex col-sm-12 col-md-5 flex-column flex-md-row justify-content-md-end align-items-md-center pl-md-0"
                                            style={{ gap: "1.5rem" }}
                                          >
                                            <div className="d-flex flex-column col-sm-4 col-md-6 p-0 justify-content-center">
                                              <OverlayTrigger
                                                placement="top"
                                                overlay={
                                                  <Tooltip id="canceled-transfer-tooltip">
                                                    {t(
                                                      "NominalTransfer.transferReturnedMessage"
                                                    )}
                                                  </Tooltip>
                                                }
                                              >
                                                <div className="transfer-canceled px-2 py-1 text-center">
                                                  {t(
                                                    "NominalTransfer.transferCanceled"
                                                  )}
                                                </div>
                                              </OverlayTrigger>
                                            </div>
                                          </div>
                                        )}

                                        {activeTransfer &&
                                          activeTransfer.user_id ===
                                            user.id && (
                                            <div className="d-flex col-sm-12 col-md-4 flex-column flex-md-row w-100 justify-content-md-end align-items-md-center">
                                              <div className="transferred text-center px-2 py-1 w-100">
                                                {t("MyTickets.transferred")}
                                              </div>
                                            </div>
                                          )}

                                        {(ticket.transfers?.length === 0 ||
                                          (activeTransfer?.active === 1 &&
                                            activeTransfer?.destination_user_id ===
                                              user.id) ||
                                          (canceledTransfer?.active === 1 &&
                                            canceledTransfer?.user_id ===
                                              user.id)) && (
                                          <div
                                            className="d-flex col-sm-12 col-md-5 flex-column flex-md-row justify-content-md-end align-items-md-center pr-md-3  p-0"
                                            style={{ gap: "1.5rem" }}
                                          >
                                            <div
                                              className="d-flex flex-column col-12 p-0"
                                              style={{ gap: "0.5rem" }}
                                            >
                                              <div className="d-flex flex-row justify-content-end gap-3">
                                                {!!ticket.show_transfer_button && (
                                                  <Button
                                                    as={"a"}
                                                    className="btn btn-ticket align-items-center w-100"
                                                    href={`/transferencia-nominal?ticket=${ticket.id}`}
                                                    onClick={() =>
                                                      sendTransferEventAction(
                                                        order
                                                      )
                                                    }
                                                  >
                                                    <PaperPlane
                                                      width={18}
                                                      height={18}
                                                    />
                                                    {t("MyTickets.transfer")}
                                                  </Button>
                                                )}

                                                {allOrder.status ===
                                                  "finalizado" &&
                                                  (!isWhitelabel ? (
                                                    <NewButton
                                                      className={`primary-contained ${
                                                        ticket.show_transfer_button
                                                          ? ""
                                                          : "col-12 col-md-6"
                                                      }`}
                                                      onClick={() =>
                                                        handleSeeTicket(
                                                          allOrder,
                                                          order,
                                                          ticket
                                                        )
                                                      }
                                                      content={
                                                        <>
                                                          <Ticket
                                                            color="#FFFFFF"
                                                            width={18}
                                                            height={18}
                                                          />
                                                          {t(
                                                            "MyTickets.seeTicket"
                                                          )}
                                                        </>
                                                      }
                                                    />
                                                  ) : (
                                                    <Button
                                                      as="a"
                                                      className="btn btn-ticket align-items-center w-100"
                                                      target="_blank"
                                                      rel="noopener noreferrer"
                                                      onClick={() =>
                                                        downloadPdfTicket(
                                                          order,
                                                          ticket
                                                        )
                                                      }
                                                    >
                                                      <Download
                                                        width={18}
                                                        height={18}
                                                      />
                                                      {t("MyTickets.download")}
                                                    </Button>
                                                  ))}
                                              </div>
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                    );
                                  })}
                                </div>
                              )}
                            </Card.Body>
                          </Accordion.Collapse>
                        </Card>
                      </Accordion>
                    </Fragment>
                  ))}
                </div>
              </div>
            ))
          )}
          {!isWhitelabel && (
            <div className="d-flex d-md-none mt-4 mb-4">
              <DownloadApp
                isOpen={isOpen}
                handleOpenModal={handleOpenModal}
                seeTicket={false}
              />
            </div>
          )}
          {hasMore && (
            <div className="text-center">
              <Button
                className="button-has-more"
                onClick={() => carregaDados()}
                disabled={loadingButton}
              >
                {loadingButton && (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    style={{ margin: 0 }}
                  />
                )}
                {!loadingButton && t("MyTickets.loadMore")}
              </Button>
            </div>
          )}
        </div>
      )}
      <RechargeDigitalCardModal
        isOpen={isRechargeModalOpen}
        handleClose={() => setRechargeModalOpen(false)}
      />
    </>
  );
}
