import React, { useEffect, useState } from "react";
import styled from "styled-components";
import LinesEllipsis from "react-lines-ellipsis";
import PropTypes from "prop-types";
import emptyBannerImage from "../../assets/img/empty-banner.png";
import FavoriteButton from "../FavoriteButton";
import responsiveHOC from 'react-lines-ellipsis/lib/responsiveHOC'

import { breakPoints, COLORS, OPACITY } from "../../services/constants";
import EventHelper from "../../Helpers/event";
import { pushGAEvent } from "../../Helpers/tagManager";
import MoengageService from "../../services/moengage";
import { useAuth } from "../../hooks/useAuth";

const ResponsiveEllipsis = responsiveHOC()(LinesEllipsis)

EventCard.propTypes = {
  event: PropTypes.shape({
    name: PropTypes.string,
    slug: PropTypes.string,
    start_date: PropTypes.string,
    end_date: PropTypes.string,
    thumb: PropTypes.string,
    is_online: PropTypes.bool,
    event_location: PropTypes.object,
  }),
  promoterCode: PropTypes.string
};

export default function EventCard(props) {
  const { user } = useAuth();

  const [cardOpacity, setCardOpacity] = React.useState(OPACITY.normal);
  const [disableClick, setDisableClick] = useState(false);
  const [dateCardColor, setDateCardColor] = useState(COLORS.darkgray);

  const { event, promoterCode } = props;

  const day = EventHelper.startDay(event);
  const month = EventHelper.startMonth(event);
  const eventLocation = event.event_location?.name
    ? `${event.event_location?.city}, ${event.event_location?.name}`
    : event.event_location?.formatted_address;

  const handleNavigation = async () => {
    if (!disableClick) {
      pushGAEvent("select_content", {
        content_type: "event_card",
        content_id: event.id,
      });

      if (user) {
        await MoengageService.createEvent({
          type: "event",
          customer_id: user.id,
          actions: [
            {
              action: "Select Content",
              attributes: {
                contentType: "event_card",
                contentId: event.id,
              },
              platform: "web",
            },
          ],
        });
      }

      window.location.assign(`/eventos/${event.slug}${promoterCode ? `?code=${promoterCode}` : ''}`);
    }
  };

  useEffect(() => {
    if (event.is_closed) {
      setCardOpacity(OPACITY.light);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <EventCardContainer
      onClick={handleNavigation}
      onMouseEnter={() => setDateCardColor(COLORS.gray)}
      onMouseLeave={() => setDateCardColor(COLORS.darkgray)}
    >
      <PaddingContainer style={{ opacity: cardOpacity }}>
        <DateImageContainer>
          <DateContainer
            style={{
              backgroundColor: dateCardColor,
              transition: "all 100ms ease-in-out",
            }}
          >
            <DayText>{day}</DayText>
            <MonthText>{month.toUpperCase()}</MonthText>
          </DateContainer>
          <ImageContainer
            style={{
              backgroundImage: `url(${event.thumb || event.banner || emptyBannerImage
                })`,
            }}
          >
            <DateInsideContainer
              style={{
                backgroundColor: dateCardColor,
                transition: "all 100ms ease-in-out",
              }}
            >
              <DayText>{day}</DayText>
              <MonthText>{month.toUpperCase()}</MonthText>
            </DateInsideContainer>
          </ImageContainer>
        </DateImageContainer>
        <HeaderNameLocalContainer>
          <NameText
            text={event.name}
            maxLine={2}
            ellipsis="..."
            style={{ height: 50, overflow: "hidden" }}
          />
          {!!eventLocation && (
            <TextAndIconContainer style={{ width: "70%" }}>
              <i className="fas fa-map-marker-alt fa-xs" />
              <div className="d-flex flex-column w-100">
                <LocalText text={`${event.event_location.city}, ${event.event_location.state}`} maxLine={1} ellipsis="..." basedOn="letters" trimRight />
                <LocalText text={event.event_location?.name || event.event_location?.neighborhood} maxLine={1} ellipsis="..." basedOn="letters" trimRight />
              </div>
            </TextAndIconContainer>
          )}
        </HeaderNameLocalContainer>
        <FooterNameLocalContainer>
          <NameText
            text={event.name}
            maxLine={2}
            ellipsis="..."
            style={{ height: 50, overflow: "hidden" }}
          />
          <LocalContainer>
            {!!eventLocation && (
              <TextAndIconContainer style={{ width: "75%" }}>
                <i className="fas fa-map-marker-alt fa-xs" />
                <div className="d-flex flex-column w-100">
                  <LocalText text={`${event.event_location.city}, ${event.event_location.state}`} maxLine={1} ellipsis="..." basedOn="letters" trimRight />
                  <LocalText text={event.event_location?.name || event.event_location?.neighborhood} maxLine={1} ellipsis="..." basedOn="letters" trimRight />
                </div>
              </TextAndIconContainer>
            )}
          </LocalContainer>
        </FooterNameLocalContainer>
      </PaddingContainer>
      <div
        className="row position-absolute"
        style={{ bottom: "0.625rem", right: "1.875rem" }}
      >
        <FavoriteButton
          eventId={event.id}
          eventName={event.name}
          style={{ width: "1.875rem", height: "1.875rem" }}
          onFocus={() => setDisableClick(true)}
          onBlur={() => setDisableClick(false)}
        />
      </div>
    </EventCardContainer>
  );
}

const EventCardContainer = styled.div`
  position: relative;
  box-shadow: 0 16px 64px -16px rgb(46 55 77 / 10%);
  cursor: pointer;
  &:hover {
    box-shadow: 0px 5px 10px -5px rgba(0, 0, 0, 0.43);
    transform: translate(0, -1px);
    transition: all 100ms ease-in-out;
  }
  height: 110px;
  width: 100%;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: ${COLORS.white};
  @media ${breakPoints.mobileL} {
    height: 320px;
    width: 240px;
    justify-content: flex-start;
    align-items: flex-start;
  }
  @media ${breakPoints.tablet} {
    width: 260px;
  }
`;

const FooterNameLocalContainer = styled.div`
  display: none;
  @media ${breakPoints.mobileL} {
    padding: 10px;
    display: flex;
    width: 100%;
    flex-direction: column;
    width: 240px;
  }
  @media ${breakPoints.tablet} {
    width: 260px;
  }
`;

const HeaderNameLocalContainer = styled.div`
  padding-left: 10px;
  display: flex;
  justify-content: flex-start;
  width: 100%;
  flex-direction: column;

  @media ${breakPoints.mobileL} {
    display: none;
  }
`;

const PaddingContainer = styled.div`
  position: relative;
  padding: 5px 10px 10px 10px;
  display: flex;
  width: 80%;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  justify-content: start;
  @media ${breakPoints.mobileL} {
    padding: 0;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
  }
`;

const LocalContainer = styled.div`
  display: inline-flex;
  flex-direction: row;
  width: 100%;
`;

const ImageContainer = styled.div`
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  height: 80px;
  width: 80px;
  border-radius: 50%;
  @media ${breakPoints.mobileL} {
    position: relative;
    height: 210px;
    display: flex;
    width: 240px;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 15px 15px 0 0;
  }
  @media ${breakPoints.tablet} {
    width: 260px;
  }
`;
const TextAndIconContainer = styled.div`
  display: inline-flex;
  justify-content: flex-start;
  align-items: baseline;
  flex-direction: row;
  width: 100%;
`;
const NameText = styled(ResponsiveEllipsis)`
  width: 100%;
  color: ${COLORS.darkgray};
  display: inline-block;
  align-items: center;
  justify-content: flex-start;
  font-size: 1rem;
  text-align: start;
  font-weight: bold;
  @media ${breakPoints.mobileL} {
    width: 100%;
  }
`;
const LocalText = styled(ResponsiveEllipsis)`
  padding-left: 5px;
  color: ${COLORS.gray};
  line-height: 20px;
  display: inline-block;
  align-items: center;
  justify-content: flex-start;
  font-size: 0.9rem;
  text-align: start;
  @media ${breakPoints.mobileL} {
    width: 100%;
    height: auto;
    padding-left: 8px;
  }
`;

const DateContainer = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  height: 80px;
  width: 60px;
  margin-right: 5px;
  border-radius: 10px;
  flex-direction: column;
  @media ${breakPoints.mobileL} {
    display: none;
  }
`;

const DateInsideContainer = styled.div`
  display: none;
  @media ${breakPoints.mobileL} {
    display: flex;
    justify-content: center;
    align-items: center;
    display: flex;
    height: 60px;
    width: 50px;
    border-radius: 10px 10px 10px 10px;
    flex-direction: column;
    margin: 10px 0 0 10px;
  }
`;

const DateImageContainer = styled.div`
  display: flex;
  padding-top: 5px;
  height: 80px;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  @media ${breakPoints.mobileL} {
    width: 240px;
    padding: 0;
    height: 210px;
  }
  @media ${breakPoints.tablet} {
    width: 260px;
  }
`;

const DayText = styled.span`
  font-size: 25px;
  line-height: 30px;
  text-align: center;
  font-weight: bold;
  color: ${COLORS.white};
  @media ${breakPoints.mobileL} {
    font-size: 25px;
    line-height: 25px;
  }
`;
const MonthText = styled.span`
  background-repeat: ${COLORS.blue};
  text-align: center;
  font-size: 20px;
  font-weight: lighter;
  border-radius: 20px;
  line-height: 30px;
  color: ${COLORS.white};
  @media ${breakPoints.mobileL} {
    font-size: 18px;
    line-height: 18px;
  }
`;
