import React, { Fragment, useState } from "react";
import NewButton from "../../../../../components/NewButton";
import { useTranslation } from "react-i18next";
import RechargeDigitalCardModal from "../RechargeDigitalCardModal";
import useWindowSize from "../../../../../hooks/useWindowSize";
import { pushGAEvent } from "../../../../../Helpers/tagManager";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { isInZigApp } from "../../../../../services/utils";

export default function ConcludedSection({ handleRedirect, isWhitelabel, digitalCardEnabled, handleOpenModal }) {
  const { t } = useTranslation();
  const isMobile = useWindowSize();
  const history = useHistory()
  const [openRechargeModal, setOpenRechargeModal] = useState(false);

  const handleCloseRechargeModal = () => {
    setOpenRechargeModal(false);
  }

  return (
    <Fragment>
      <div className="new-button-container d-flex flex-column justify-content-center align-items-center gap-2">
        <NewButton
          className="primary-contained"
          onClick={() => {
            if (isMobile) {
              handleRedirect(!isWhitelabel && digitalCardEnabled ? "recharge" : "myTickets", !isWhitelabel && digitalCardEnabled)
              if (!isWhitelabel && digitalCardEnabled) {
                pushGAEvent("clk_recharge_online_btn")
              }
            } else {
              if (digitalCardEnabled) {
                setOpenRechargeModal(true)
              } else {
                history.push('/meus-ingressos')
              }
            }
          }}
          content={<Fragment>{t(!isWhitelabel && digitalCardEnabled ? "CheckoutStatus.buttons.recharge" : "CheckoutStatus.buttons.myTickets")}</Fragment>}
        />

        {!isWhitelabel && <NewButton
          className="secondary-contained"
          onClick={() => {
            if (digitalCardEnabled) {
              if (isMobile && isInZigApp()) {
                window.location.href = "https://zigpay.page.link/meus-ingressos"
              } else {
                history.push('/meus-ingressos')
              }
            } else {
              isMobile ? handleRedirect("downloadApp") : handleOpenModal()
            }
          }}
          content={<Fragment>{t(digitalCardEnabled ? "CheckoutStatus.buttons.myTickets" : "CheckoutStatus.buttons.downloadZigApp")}</Fragment>}
        />}
      </div>
      <RechargeDigitalCardModal isOpen={openRechargeModal} handleClose={handleCloseRechargeModal} />
    </Fragment>
  );
}
